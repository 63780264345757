// import ScrollService from './NativeScroll'
import ScrollService from './VirtualScroll';

ScrollService.getScrollOffset = ({element}) => {
    let y = 0
    const popupnavEl = document.getElementById('popup-nav-opener')
    if (popupnavEl) {
        const rect = popupnavEl.getBoundingClientRect()
        y = rect.top + rect.height
    }

    const overlapEl = element.parentElement?.closest('[data-component="ScrollOverlap"]')
    if (overlapEl) {
        const overlap = Component.getFromElement(overlapEl)
        if (overlap) {
            y += overlap.state?.y ?? 0
        }
    }

    return y
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService
