export const EVENTS = {
    SWUP_CLICK_LINK: 'swup:clicklink',
    SWUP_WILL_REPLACE_CONTENT: 'swup:willreplacecontent',
    SWUP_CONTENT_REPLACED: 'swup:contentreplaced',

    POPUPNAV_CLOSESTART: 'popupnav:closestart',
    POPUPNAV_CLOSEEND: 'popupnav:closeend',
    POPUPNAV_OPENSTART: 'popupnav:openstart',
    POPUPNAV_OPENEND: 'popupnav:openend',

    DOM_CHANGE: 'domchange'
}