import ScrollService from "../Scroll"

const scrollHistory = {}

export default {
    saveCurrent: function() {
        if (!window.history.state) {
            return
        }

        scrollHistory[window.history.state.random] = {
            scroll: ScrollService.getScroll(),
            height: document.documentElement.offsetHeight
        }
    },
    getCurrent: function() {
        if (!window.history.state) {
            return null
        }
        
        return scrollHistory[window.history.state.random]
    }
}