import { createLoading } from '../../../meta/loading'

export class NetteLoadingExtension {
    initialize(naja) {
        naja.uiHandler.addEventListener('interaction', this.handleUiHandlerInteraction)
        naja.addEventListener('before', this.handleBefore);
        naja.addEventListener('start', this.handleStart)
        naja.addEventListener('complete', this.handleComplete)
    }

    handleBefore = event => {
        if (event.detail.options.autosubmitForm && !event.detail.options.loading) {
            const loading = createLoading(event.detail.options.autosubmitForm)
            event.detail.options.loading = loading
        }
    }

    handleUiHandlerInteraction = event => {
        const loaderEl = event.detail.element.closest('.ajax')
        if (loaderEl && !event.detail.options.loading) {
            const loading = createLoading(loaderEl)
            event.detail.options.loading = loading
        }
    }

    handleStart = event => {
        const loading = event.detail.options.loading
        if (loading) {
            loading.show()
        }
    }

    handleComplete = event => {
        const loading = event.detail.options.loading
        if (loading) {
            loading.destroy()
        }
    }
}