import throttle from 'lodash/throttle'

import { NativeScroll } from './NativeScroll'

let Scrollbar = null

class VirtualScroll extends NativeScroll {
    constructor() {
        super()

        this.container = document.querySelector('.scroll-Container')
        this.scrollbar = null
        this.handleResize = throttle(this.handleResize, 50)
    }

    useNative() {
        if (this.isVirtual) {
            this.scrollbar.destroy()
            this.scrollbar = null
        }

        this.container.classList.remove('is-virtual')
        super.attach()
        this.isVirtual = false
    }

    async useVirtual() {
        if (this.isVirtual) {
            return
        }

        if (!Scrollbar) {
            try {
                const module = await import(
                    /* webpackChunkName: "virtualscroll-smoothscroll" */
                    'smooth-scrollbar'
                )
                Scrollbar = module.default
            } catch (error) {
                throw 'Unable to load smoothscroll'
            }
        }

        super.detach()
        const scroll = super.getScroll()

        this.container.classList.add('is-virtual')

        this.scrollbar = Scrollbar.init(this.container, {
            renderByPixels: false
        })
        this.scrollbar.addListener(this.handleVirtualScroll)

        this.isVirtual = true
        this.setPosition(scroll.x, scroll.y)
    }


    getOffset() {
        if (this.isVirtual) {
            return this.scrollbar.offset
        } else {
            return super.getOffset()
        }
    }

    getLimit() {
        if (this.isVirtual) {
            return this.scrollbar.limit
        } else {
            if (this.container && this.shouldUpdate.limit) {
                this.limit = {
                    x: this.container.offsetWidth - this.window.width,
                    y: this.container.offsetHeight - this.window.height
                }
                this.shouldUpdate.limit = false
            }
            return super.getLimit()
        }

    }


    handleVirtualScroll = status => {
        const now = Date.now()
        const delta = now - this.velocity.time
        if (!delta || delta > 1000) {
            this.velocity.x = 0
            this.velocity.y = 0
        } else {
            this.velocity.x = (status.offset.x - this.current.x)/delta
            this.velocity.y = (status.offset.y - this.current.y)/delta
        }
        this.velocity.time = now

        this.current = {
            x: status.offset.x,
            y: status.offset.y
        }

        this.render()
    }

    setPosition(x, y) {
        y = y === 0 ? -90 : y //ios fix, overscroll nulovou pozici kvuli liste
        if (this.isVirtual) {
            this.scrollbar.setPosition(x, y)
        } else {
            super.setPosition(x, y)
        }
    }

    //MODES
        // 'force' = <default> - vzdy scrollne a zarovna element k horni hrane + offset
        // 'force-center' - vzdy scrollne a hodi element +- na center
        // 'auto' - odscrolluje, kdyz je element mimo viewport/nebo je malo videt a hodi element +- na center
        //
        // NENAKODOVANO: 'into-viewport' - odscrolluje k horni hrane, pokud neni element ve viewportu, imho se nevyuzije
    scrollToElement(element, options = {}) {
        if (!this.isVirtual) {
            super.scrollToElement(element, options)
            return
        }

        options = {
            animate: true,
            mode: 'force',
            ...options
        }

        this.scrollElementBox = element.getBoundingClientRect()

        if ((options.mode === 'auto') && this.isElementInView(element)) {
            return
        }

        let offset = options.offset ? options.offset : this.getScrollOffset({element})
        if (options.mode === 'auto' || options.mode === 'force-center') {
            offset = offset + this.getOffsetToCenterElement({element})
        }

        if (options.animate) {
            this.scrollbar.scrollIntoView(element, {
                offsetTop: offset,
                onlyScrollIfNeeded: options.loose,
                alignToTop: true
            })
        } else {
            this.scrollbar.setPosition(0, this.getScroll().y + this.scrollElementBox.top - offset)
        }
    }

    getScrollbarModule() {
        return Scrollbar
    }
}

export default new VirtualScroll
