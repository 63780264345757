import { queryAll } from "../utils/dom"

//
// vytvori loader ve vybranem elementu
// prida tridu .u-loading na vybranem elementu
// pokud existuje [data-loading] atribut na vybranem elementu, tak vytvori dalsi subloadery
//


export function createLoading(element, options = {}) {
    if (!element) {
        console.error('Specify element where a loader is supposed show')
        return {
            show: () => { },
            destroy: () => { },
        }
    }

    options = {
        delay: 500,
        canCreateSubloadings: true,
        ...options
    }

    let loader = null
    const subloadings = []
    let timerShow = null

    const destroy = () => {
        clearTimeout(timerShow)
        let loadingCount = parseInt(element.dataset.loadingCount)
        if (Number.isNaN(loadingCount)) {
            loadingCount = 0
        }
        loadingCount -= 1
        element.dataset.loadingCount = loadingCount
        if (loadingCount <= 0) {
            element.classList.remove('u-loading')
            if (loader && loader.parentElement) {
                loader.parentElement?.removeChild(loader)
                loader = null
            }
        }

        subloadings.forEach(_subloading => {
            _subloading.destroy()
        })
    }

    const show = () => {
        timerShow = setTimeout(() => {
            let loadingCount = parseInt(element.dataset.loadingCount)
            if (Number.isNaN(loadingCount)) {
                loadingCount = 0
            }
            loadingCount += 1
            element.dataset.loadingCount = loadingCount

            const createHTML = () => {
                element.classList.add('u-loading')
                loader = document.createElement('div')
                loader.className = 'Loader'
                loader.innerHTML = `
                    <div class="Spinner">
                        <svg viewBox="25 25 50 50">
                            <circle cx="50" cy="50" r="15" />
                        </svg>
                    </div>
                `
                element.appendChild(loader)
            }

            if (element.dataset.loading && options.canCreateSubloadings) {
                const subelements = queryAll(element.dataset.loading)

                subelements.forEach(subelement => {
                    const canCreateSubloadings = subelement !== element //osetreni infinity rekurze
                    subloadings.push(createLoading(subelement, {
                        delay: 0,
                        canCreateSubloadings
                    }))
                })
            } else {
                createHTML()
            }

            subloadings.forEach(_subloading => {
                _subloading.show()
            })
        }, options.delay)
    }


    return {
        getElement() {
            return element
        },
        destroy,
        show
    }
}