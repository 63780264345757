// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'


// nette
// import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

import NajaService from './services/Naja'
NajaService.initialize()

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { android, iOS, SAFARI, CMS } from './core/config'
import ScrollService from './services/Scroll'
if ( android || iOS || CMS || SAFARI || (typeof FORCE_NATIVE_SCROLL !== 'undefined' && FORCE_NATIVE_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    ScrollService.useVirtual()
        .catch(err => {
            ScrollService.useNative()
            console.log(err)
        })
        .finally(() => setScrollPosition())
}

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'



import Swup from 'swup'
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import {EVENTS} from './core/Events'
import scrollHistory from './services/ScrollHistory'

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}
const lastScrollPosition = {
    x: 0,
    y: 0,
}

if (!CMS) {
    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="#"]:not([data-no-swup]):not([data-component]):not(.ajax)'
        ].join(', '),
        plugins: [new SwupBodyClassPlugin(), new SwupGtmPlugin()],
        containers: ['#swup', '#modals'],
        animationSelector: '.PageTransition',
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    })

    swup.on("samePage", (event) => {
        if (event.delegateTarget.dataset.forceSamePage !== 'undefined') {
            ScrollService.scrollToElement(document.getElementById('top'))
        }
    })

    swup.on('clickLink', event => {
        // scrollHistory.saveCurrent()

        EventBus.emit(EVENTS.SWUP_CLICK_LINK, { event })
    })

    swup.on('willReplaceContent', event => {
        EventBus.emit(EVENTS.SWUP_WILL_REPLACE_CONTENT)

        queryAll('[data-swup]').forEach(element => {
            removeComponents(element);
        })
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()

        queryAll('[data-swup]').forEach(element => {
            loadComponents(element);
        })

        requestAnimationFrame(() => {
            ScrollService.resize()

            const historyState = scrollHistory.getCurrent()
            if (historyState) {
                lastScrollPosition.x = historyState.scroll.x
                lastScrollPosition.y = historyState.scroll.y
            }
            setScrollPosition(lastScrollPosition.x, lastScrollPosition.y)
            EventBus.emit(EVENTS.SWUP_CONTENT_REPLACED)
        })
    })

    swup.on('popState', function () {
        // EventBus.emit(EVENTS.SWUP_POPSTATE)

        // const historyState = scrollHistory.getCurrent()
        // if (historyState) {
        //     document.body.style.height = `${historyState.height}px`
        //     document.documentElement.classList.add('is-popstate')
        // }
    })
}